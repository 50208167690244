<template>
  <div :class="[{ show: open }, 'modal']">
    <!-- Modal content -->
    <div class="modal-content">
      <span v-if="closeIcon" class="close" @click.stop="$emit('close')">
        <img width="20" src="/x-circle.svg" alt="" />
        <!-- &times; -->
      </span>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: Boolean,
    closeIcon: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
/* The Modal (background) */
.modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  // padding-top: 100px; /* Location of the box */
  left: 0;
  opacity: 0;
  top: -100%;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  //   animation-direction: reverse;
  //   -webkit-animation-name: animatehide;
  //   -webkit-animation-duration: 0.4s;
  //   animation-name: animatehide;
  //   animation-duration: 0.4s;
  &.show {
    opacity: 1;
    top: 0;
    > .modal-content {
      //   animation-direction: reverse;
      -webkit-animation-name: animatetop;
      -webkit-animation-duration: 0.4s;
      animation-name: animatetop;
      animation-duration: 0.4s;
    }
  }
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  // width: 80%;
  border-radius: 10px;
  position: relative;
}

/* The Close Button */
.close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* Add Animation */
@-webkit-keyframes animatehide {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -300px;
    opacity: 0;
  }
}

@keyframes animatehide {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -300px;
    opacity: 0;
  }
}
</style>