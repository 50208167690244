<template>
  <div class="orderReceive">
    <NavbarBack :title="options.title" />
    <div style="height: 50px"></div>
    <div v-if="fillOrderList.length > 0">
      <div class="topAction">
        <div @click="clickClear()" class="btn clear">ล้างค่า</div>
        <div @click="clickFill()" class="btn fillReceive">
          {{ options.btnFillText }}
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in orderlist" :key="index">
          <OrderListItemDetail :item="item" :showDelivery="true" />
          <div class="fillSection">
            <div>{{ options.labelInputText }}</div>
            <div>
              <input
                type="number"
                pattern="\d*"
                v-model.number="fillOrderList[index].qty"
              />
            </div>
            <div>{{ item.product.unit }}</div>
          </div>
          <div class="errorMessage">{{ fillOrderList[index].error }}</div>
        </div>
      </div>
      <div class="bottomAction">
        <div
          :class="[{ disable: valueError }, 'btn confirm']"
          @click="confirmAlert = true"
        >
          {{ options.confirmText }}
        </div>
      </div>
      <div style="height: 100px"></div>
    </div>

    <!-- alert confirm -->
    <Modal :open="confirmAlert" :closeIcon="false" class="alertConfirm">
      <div class="alertBody">
        <div class="alertTitle"><b>ยืนยันบันทึกข้อมูล</b></div>
        <div>
          <div class="noteTitle">หมายเหตุ</div>
          <textarea
            v-model="note"
            id="noteConfirm"
            class="noteInput"
            rows="4"
          ></textarea>
        </div>
      </div>
      <div class="wrapAction">
        <div id="cancelBtn" class="action" @click="confirmAlert = false">
          ปิด
        </div>
        <div id="okBtn" @click="confirm()" class="action">ยืนยัน</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import NavbarBack from "@/components/NavbarBack";
import OrderListItemDetail from "../components/OrderListItemDetail.vue";
import Modal from "@/components/Modal";
import { GET, POST } from "@/utils/http";

export default {
  props: { orderId: String, options: Object },
  components: { NavbarBack, OrderListItemDetail, Modal },
  data() {
    return {
      orderlist: [],
      fillOrderList: [],
      valueError: false,
      confirmAlert: false,
      note: "",
    };
  },
  watch: {
    fillOrderList: {
      handler(list) {
        this.valueError = false;
        for (let [index, e] of list.entries()) {
          e.error = "";
          if (!Number.isInteger(e.qty)) {
            this.valueError = true;
            e.error = "ต้องเป็นจำนวนเต็ม";
            continue;
          }

          ({
            receive: () => {
              if (
                e.qty >
                this.orderlist[index].ship - this.orderlist[index].receive
              ) {
                console.log("error");
                this.valueError = true;
                e.error = "ต้องน้อยกว่าหรือเท่ากับที่ต้องรับ";
              }
            },
            ship: () => {
              if (
                e.qty >
                this.orderlist[index].qty - this.orderlist[index].ship
              ) {
                console.log("error");
                this.valueError = true;
                e.error = "ต้องไม่น้อยกว่าหรือเท่ากับค้างส่ง";
              }
            },
          }[this.options.apiPath]());
        }
      },
      deep: true,
    },
  },
  methods: {
    async getOrderlists() {
      const { status, body } = await GET(
        `/api/shops/${this.$myShop.id}/orders/${this.orderId}/orderlists?query=&order=unitPrice:desc`
      );
      if (status !== 200) {
        throw "order: not ok";
      }
      this.orderlist = body;
    },
    clickClear() {
      this.fillOrderList.forEach((e) => (e.qty = 0));
    },
    clickFill() {
      this.fillOrderList.forEach((fo) => {
        const item = this.orderlist.find((o) => o.id == fo.orderListId);
        ({
          receive: () => (fo.qty = item.ship - item.receive),
          ship: () => (fo.qty = item.qty - item.ship),
        }[this.options.apiPath]());
      });
    },
    async confirm() {
      // if (!(await window.$alert("ยืนยันบันทึกข้อมูล?", "confirm"))) {
      //   return;
      // }
      const result = await this.handleFetch(this.updateDelivery());
      if (result) {
        await window.$alert("บันทึกข้อมูลแล้ว");
        this.$router.push(`/order/${this.orderId}`);
      }
    },
    async updateDelivery() {
      const formData = new FormData();
      formData.append("orderList", JSON.stringify(this.fillOrderList));
      formData.append("note", this.note);

      const { status } = await POST(
        `/api/shops/${this.$myShop.id}/orders/${this.orderId}/delivery/${this.options.apiPath}`,
        formData
      );
      if (status !== 200) {
        throw `update ${this.options.apiPath}: not ok is status ${status}`;
      }
    },
    async handleFetch(fn) {
      try {
        window.loading(true);
        await fn;
        return true;
      } catch (err) {
        console.error(err);
        window.loading(false);
        await window.$alert(
          "พบข้อผิดพลาด กรุณาใช้งานใหม่ภายหลัง หรือติดต่อเจ้าหน้าที่"
        );
        this.$router.back();
        return false;
      } finally {
        window.loading(false);
      }
    },
  },
  async mounted() {
    await this.handleFetch(this.getOrderlists());
    this.fillOrderList = this.orderlist.map((e) => {
      return { orderListId: e.id, qty: 0 };
    });
  },
};
</script>

<style lang="scss" scoped>
.topAction {
  display: flex;
  justify-content: center;
  padding: 0 10px;
  .btn {
    margin: 0 10px;
    padding: 10px;
    max-width: 240px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }

  .clear {
    color: #666666;
    background: #efefefff;
  }

  .fillReceive {
    color: var(--primary-color);
    background: #d9ead3ff;
  }
}

.list {
  padding: 0 15px;
}

.item {
  width: 100%;
  max-width: 600px;
  margin: 15px auto;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #ddd;
  .fillSection {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      margin: 0 5px;
    }
    input {
      text-align: center;
      font-weight: bold;
      padding: 3px 8px;
      width: 100%;
      font-size: 16px;
      max-width: 100px;
      border: 1px solid rgb(218, 218, 218);
      border-radius: 8px;
      min-height: 10px;
      color: rgb(63, 63, 63);
      display: block;
      margin: 0 auto;
    }
  }
}

.bottomAction {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 2px 10px;
  background: rgba(255, 255, 255, 0.63);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  .confirm {
    background: var(--primary-color);
    color: #fff;
    &.disable {
      pointer-events: none;
      color: rgb(210, 210, 210);
      background: #888888cd;
    }
  }
}

.errorMessage {
  margin-top: 5px;
  text-align: right;
  color: brown;
}

/* The Modal (background) */
.alertConfirm,
.alertCancel {
  .alertBody {
    color: rgb(122, 122, 122);
    margin: 0 0 5px 0;
    line-height: 30px;
    letter-spacing: 0.5px;
    font-size: unset !important;
    min-width: max-content;
    width: 65vw;
    max-width: 400px;
  }
  .alertTitle {
    font-size: 17px;
    text-align: center;
  }

  .noteTitle {
    font-size: 14px;
  }

  .noteInput {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgb(212, 212, 212);
  }
}

.alertConfirm {
  #okBtn {
    background: var(--primary-color);
    color: #fff;
  }

  #cancelBtn {
    background: #fff;
    color: rgb(117, 117, 117);
    border: 2px solid rgb(218, 218, 218);
  }

  .wrapAction {
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
  }
  .action {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0 5px 0 5px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    &:active {
      background: rgba(226, 226, 226, 0.507);
    }
  }
}
</style>