<template>
  <OrderDeliverUpdate :options="options" :orderId="orderId" />
</template>

<script>
import OrderDeliverUpdate from "./components/OrderDeliverUpdate.vue";
export default {
  props: { orderId: String },
  components: { OrderDeliverUpdate },
  data() {
    return {
      options: {
        title: "บันทึกการรับสินค้า",
        btnFillText: "กรอกจำนวนรอรับ",
        labelInputText: "บันทึกรับเพิ่ม",
        confirmText: "บันทึกข้อมูลรับสินค้า",
        apiPath: "receive",
      },
    };
  },
};
</script>